import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';

import { ChartPanel } from './ChartPanel';
import { MetricsPanel } from './MetricsPanel';
import { SummaryPanel } from './SummaryPanel';
import { reportStateReducer, defaultReportState, ReportStateContext } from './ReportState';
import CONFIG from '../config/config';
import { ErrorScreen } from '../common/ErrorScreen';
import { parseQueryString } from '../util/urlHelper';
import { httpGetJson } from '../util/fetchHelper';

const PageDiv = styled.div`
  background-color: #F9F9F9;
  height: 100vh;
`;

const PageHeader = styled.div`
  background-color: #fff;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #112232;
  padding: 20px;
`;

const getTitle = (storeName?: string) => {
  return storeName ? `${storeName.substring(0, 2)}***` : '发券宝';
}

export const ReportPage = React.memo(() => {
  const [state, dispatch] = useReducer(reportStateReducer, defaultReportState);
  useEffect(() => {
    const storeId = parseQueryString().get('storeId');
    if (!storeId) {
      dispatch({type: 'DATA_ERROR', payload: '参数错误，无店铺ID'})
      return;
    }
    httpGetJson(
      `${CONFIG.API_URL}/store/${storeId}/ecd_performance?period=${state.period}`,
      data => dispatch({type: 'DATA_ARRIVED', payload: data}),
      error => {
        if (error === '100400001') {
          dispatch({type: 'DATA_ERROR', payload: '抱歉当前店铺还未注册发券宝服务，请联系客服开通'})
        }
        else {
          dispatch({type: 'DATA_ERROR', payload: error ? error : ''})
        }
      }
    );
  }, [state.period]);

  if (state.error) {
    return <ErrorScreen title="出错啦" msg={state.error}/>
  }
  return (
  <PageDiv>
    <ReportStateContext.Provider value={{state, dispatch}}>
      <PageHeader>{getTitle(state.data?.store_name)}</PageHeader>
      <MetricsPanel />
      <ChartPanel />
      <SummaryPanel />
    </ReportStateContext.Provider>
  </PageDiv>
  );
});
