interface AppConfig {
  APP_NAME: string;
  API_URL: string;
}

let localConfig = {};
try {
  localConfig = require('./config.json');
} catch (err) {
  console.warn('No config file found, using default');
}

let envConfig = {};
try {
  envConfig =
    window?.location?.hostname === 'ecd.csschina.com/'
      ? require('./config-production.json')
      : require('./config-staging.json');
} catch (err) {}

const defaultConfig: AppConfig = {
  APP_NAME: 'ECD',
  API_URL: 'https://fc-api.csschina.com'
};

const appConfig = { ...defaultConfig, ...localConfig, ...envConfig };
export default appConfig;
