import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CONFIG from '../config/config';
import { ErrorScreen } from '../common/ErrorScreen';
import { Loading } from '../common/Loading';
import { parseQueryString } from '../util/urlHelper';
import { httpGetJson } from '../util/fetchHelper';

const PageContainer = styled.div`
  > h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #112232;
    padding: 24px 16px;
    border-bottom: 1px solid #F0EFEF;
  }
`;

const HeadDiv = styled.div`
  display: flex;
  justify-content: center;
  > img {
    width: 100%;
    max-width: 512px;
    max-height: 512px;
  }
`;

const StoreOptionDiv = styled.div`
  margin: 0 12px;
  padding: 16px 4px;
  border-bottom: 1px solid #F0EFEF;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #112232;
  display: flex;
  justify-content: space-between;
  > a {
    font-weight: normal;
    text-decoration: none;
    color: #FF5530;
  }
`;

interface StoreInfo {
  status: 'active' | 'to_be_activated' | 'inactive' | 'NA';
  name: string;
}

type StoreWithId = {
  id: string;
} & StoreInfo;

const descUrl = './marketing/index.html';
const goDesc = () => window.location.assign('./marketing/index.html');

const StoreOption = ({store: {id, status, name}}: {store: StoreWithId}) => {
  const history = useHistory();
  const reportUrl = `/report?storeId=${id}`;
  const goReport = () => history.push(reportUrl);
  const activeUrl = `/activate?storeId=${id}&storeName=${encodeURIComponent(name)}`;
  const goActive = () => history.push(activeUrl);

  switch(status) {
    case 'active':
      return (
        <StoreOptionDiv onClick={goReport}>
          <div>{name}</div>
          <Link to={reportUrl}>进入 &gt;</Link>
        </StoreOptionDiv>
      );
    case 'to_be_activated':
      return (
        <StoreOptionDiv onClick={goActive}>
          <div>{name}</div>
          <Link to={activeUrl}>去激活 &gt;</Link>
        </StoreOptionDiv>
      );
    case 'NA':
    default:
      return (
        <StoreOptionDiv onClick={goDesc}>
          <div>{name || '*'}</div>
          <a href={descUrl}>去了解 &gt;</a>
        </StoreOptionDiv>
      );
  }
};

export const SelectStorePage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [stores, setStores] = useState<StoreWithId[]>();
  const history = useHistory();

  useEffect(() => {
    const storesStr = parseQueryString().get('stores')||'';
    const storesData = storesStr.split(',').map(str => ({
      id: str.substring(0, 36),
      name: decodeURIComponent(str.substring(36) || '')
    }));

    httpGetJson(
      `${CONFIG.API_URL}/store_enrollment?store_ids=${storesData.map(d => d.id).join(',')}`,
      data => {
        const storeInfoList = Object.entries(data).map(
          ([id, value]) => {
            const storeInfo = value as StoreInfo;
            if (!storeInfo.name) {
              storeInfo.name = storesData.find(d => d.id === id)?.name || '';
            }
            return {id, ...storeInfo};
          }
        );
        setStores(storeInfoList);
        if (!storeInfoList.find(info => info.status === 'active' || info.status === 'to_be_activated')) {
          goDesc();
        }
      }
      ,
      setError,
      () => setLoading(false)
    );
    
  }, [history]);

  if (error) {
    return <ErrorScreen title="出错啦" msg={error}/>
  }

  return (
    <PageContainer>
      <HeadDiv>
        <img src="/topimg.png" alt="page header"/>
      </HeadDiv>
      {loading && <Loading />}
      {stores && <>
        <h1>请选择你的门店</h1>
        {stores.map(store => <StoreOption store={store} key={store.id}/>)}
      </>
      }
    </PageContainer>
  );
};
