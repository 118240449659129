import React from 'react';
import styled from 'styled-components';

interface ToggleOption {
  value: string;
  label: string;
}

interface ToggleProps {
  options: [ToggleOption, ToggleOption];
  value: string;
  onToggle: (value: string) => void;
}

const ToggleDiv = styled.div`
  border: 1px solid #ECECEC;
  border-radius: 4px;
`;

const ToggleOptionDiv = styled.div<{checked: boolean}>`
  background-color: ${props => props.checked ? '#FFFFFF' : '#ECECEC'};
  color: ${props => props.checked ? '#000000' : '#707070'};
  display: inline-block;
  vertical-align: bottom;
  padding: 8px 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
`;

export const Toggle = React.memo(({options, value, onToggle}: ToggleProps) => {
  const onClick = () => {
    const newSelection = options.find(opt => opt.value !== value);
    if (newSelection){
      onToggle(newSelection.value);
    }
  };
  return (
    <ToggleDiv onClick={onClick}>
      {options.map(opt => <ToggleOptionDiv key={opt.value} checked={value === opt.value}>{opt.label}</ToggleOptionDiv>)}
    </ToggleDiv>
  );
});
