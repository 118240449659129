import React, { useContext } from 'react';
import styled from 'styled-components';

import { Panel } from './Panel';
import BestPic from './Best.png';
import { ReportStateContext } from './ReportState';

const SummaryDiv = styled(Panel)`
  display: flex;
  align-items: center;
`;

const SummaryText = styled.div`
  margin-left: 16px;
  flex-grow: 1;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #707070;
`;

const IllustrationImg = styled.img`
  flex-grow: 0;
  width: 50px;
  height: 48px;
`;

const HeighlightText = styled.span`
  color: #FF5530;
`;

const RecommendLink = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
  color: #EE9502;
`;

export const SummaryPanel = () => {
  const {
    state: {
      data
    }
  } = useContext(ReportStateContext);
  return (
  <SummaryDiv>
    <IllustrationImg src={BestPic} alt="Illustration"/>
    <SummaryText>
      <p>
      您的发券宝获客成本仅为<HeighlightText>{data?data.ecd_eater_acquisition_cost:'?'}元/人</HeighlightText>。外卖平台上广告获客成本平均为20元/人。
      </p>
      <p>
        <RecommendLink href="#">推荐给朋友获得一周免费使用</RecommendLink>
      </p>
    </SummaryText>
  </SummaryDiv>);
};
