import styled from "styled-components";

import ErrorPic from './error.png';

interface ErrorProps {
  msg?: string;
  title?: string;
}

const ErrorDiv = styled.div`
  padding-top: 136px;
`;

const ErrorImg = styled.img`
  display: block;
  width: 190px;
  height: 190px;
  margin: 0 auto;
`;

const ErrorTitle = styled.div`
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-top: 32px;
  margin-bottom: 28px;
  color: #112232;
`;

const ErrorMsg = styled.div`
  display: block;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #707070;
  opacity: 0.7;
`; 

export const ErrorScreen = ({ msg, title }: ErrorProps) => {
  return (
    <ErrorDiv>
      <ErrorImg src={ErrorPic} alt="出错信息"/>
      <ErrorTitle>{title}</ErrorTitle>
      {msg && <ErrorMsg>{msg.toString()}</ErrorMsg>}
    </ErrorDiv>
  );
};
