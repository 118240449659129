import React from 'react';
import styled from 'styled-components';

import SelectedRadio from './radio-selected.svg';
import UnselectedRadio from './radio-unselected.svg';

const RadioWrapper = styled.div`
  display: inline-block;
`;

const RadioIcon = styled.img<{size?: number}>`
  width: ${props => props.size || 20}px;
  height: ${props => props.size || 20}px;
`;

interface RadioProps {
  checked?: boolean;
  onChecked?: () => void;
  size?: number;
}

export const Radio = ({checked, onChecked, size}: RadioProps) => {
  return (
    <RadioWrapper>
      <RadioIcon
        src={checked ? SelectedRadio : UnselectedRadio}
        onClick={() => onChecked && onChecked()}
        alt="radio"
        size={size}
      />
    </RadioWrapper>
  );
};