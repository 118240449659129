import styled from 'styled-components';

import loadingSvg from './loading.svg';

interface LoadingProps {
  height?: string;
  color?: string;
};

const LoadingDiv = styled.div<LoadingProps>`
  display: flex;
  align-items:center;
  justify-content:center;
  ${props => props.height? 'height: ' + props.height : ''}
  ${props => props.color? 'color: ' + props.color : ''}
`;

export const Loading = (props: LoadingProps) => {
  return (
    <LoadingDiv {...props}>
      <img src={loadingSvg} alt="Loading Icon" />
      <div>加载中...</div>
    </LoadingDiv>
  );
};
