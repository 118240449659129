export const parseQueryString = () => {
  const paramMap = new Map<string, string>();
  const queryString = window.location.search;
  if (!queryString || !queryString.startsWith('?')) {
    return paramMap;
  }
  queryString.substring(1).split('&').forEach(s => {
    const pair = s.split('=');
    if (pair && pair.length === 2) {
      paramMap.set(pair[0], pair[1]);
    }
  })
  return paramMap;
};
