import React from 'react';

export interface ReportData {
  boosted_revenue: number;
  boosted_eaters: number;
  ecd_eater_acquisition_cost: number;
  store_name: string;
  daily_stats: [
    {
      date: string;
      ecd: {
        new_eaters_no: number;
        revenue: number;
        avg_coupon_value: number;
      },
      store_performance: {
        total_eaters: number;
        eaters_from_ecd: number;
        eaters_from_other_source: number;
      }
    }
  ]
}

export enum ReportPeriod {
  THIS_WEEK = '1W',
  THIS_MONTH = '1M',
}

export interface ReportState {
  loading: boolean,
  error?: string,
  period: ReportPeriod;
  data?: ReportData;
}

export const defaultReportState = {
  loading: true,
  period: ReportPeriod.THIS_WEEK,
};

type ReportPageActionType = 'PERIOD_CHANGE' | 'DATA_ARRIVED' | 'DATA_ERROR';

interface ReportPageAction {
  type: ReportPageActionType;
  payload?: any;
}

export const reportStateReducer = (state: ReportState, action: ReportPageAction) => {
  switch(action.type) {
    case 'PERIOD_CHANGE':
      return {...state, period: action.payload, loading: true};
    case 'DATA_ARRIVED':
      return {...state, data: action.payload, loading: false};
    case 'DATA_ERROR':
        return {...state, error: action.payload, loading: false};
    default: return state;
  }
}

interface ReportPageContextI {
  state: ReportState,
  dispatch: (action: ReportPageAction) => void;
}

export const ReportStateContext = React.createContext<ReportPageContextI>({
  state: defaultReportState,
  dispatch: () => {}
});
