import styled from "styled-components";

import SuccessPic from './success.png';

interface SuccessProps {
  msg?: string;
  title?: string;
}

const SuccessDiv = styled.div`
  padding-top: 136px;
`;

const SuccessImg = styled.img`
  display: block;
  width: 190px;
  height: 190px;
  margin: 0 auto;
`;

const SuccessTitle = styled.div`
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-top: 32px;
  margin-bottom: 28px;
  color: #112232;
`;

const SuccessMsg = styled.div`
  display: block;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #707070;
  opacity: 0.7;
`; 

export const SuccessScreen = ({ msg, title }: SuccessProps) => {
  return (
    <SuccessDiv>
      <SuccessImg src={SuccessPic} alt="success"/>
      <SuccessTitle>{title}</SuccessTitle>
      {msg && <SuccessMsg>{msg.toString()}</SuccessMsg>}
    </SuccessDiv>
  );
};
