import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { ReportPage } from './reportPage';
import { ActivatePage } from './activatePage';
import { SelectStorePage } from './selectStorePage';

function App() {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/report">
            <ReportPage />
          </Route>
          <Route path="/activate">
            <ActivatePage />
          </Route>
          <Route path="/selectstore">
            <SelectStorePage />
          </Route>
          <Route path="/">
            <ReportPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
