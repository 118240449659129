import React, { useContext } from 'react';
import styled from 'styled-components';

import { ReportStateContext } from './ReportState';

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0 0 25px 0;
  padding-top: 28px;
`;


const MetricCellDiv = styled.div<{ seperator?: boolean }>`
  padding: 0 28px;
  ${props => props.seperator ? 'border-left: 1px dashed black;' : ''}
`;
interface MetricCellProps {
  desc: string;
  unit: string;
  value?: number;
  seperator?: boolean;
}

const MetricCellDesc = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #707070;
  margin-bottom: 8px;
`;

const MetricValue = styled.span`
  font-family: Arial;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #FF5530;
`;
const MetricUnit = styled.span`
  font-family: Arial;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #FF5530;
`;

const MetricCell = ({ desc, value, unit, seperator }: MetricCellProps) => {
  return (
    <MetricCellDiv seperator={seperator}>
      <MetricCellDesc>{desc}</MetricCellDesc>
      <div>
        <MetricValue>{value === undefined ? '?' : value}</MetricValue>
        <MetricUnit>{unit}</MetricUnit>
      </div>
    </MetricCellDiv>
  );
};

export const MetricsPanel = React.memo(() => {
  const {
    state: {
      data
    }
  } = useContext(ReportStateContext);

  return (
  <MetricsGrid>
    <MetricCell desc="发券宝帮您获得新客" value={data?.boosted_eaters} unit="人" />
    <MetricCell desc="发券宝帮您增收" value={data?.boosted_revenue} unit="元" seperator={true} />
  </MetricsGrid>
  );
});
