import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CONFIG from '../config/config';
import { ErrorScreen } from '../common/ErrorScreen';
import { Loading } from '../common/Loading';
import { Radio } from '../common/Radio';
import { SubmitButton } from '../common/SubmitButton';
import { httpPostJson } from '../util/fetchHelper';
import { parseQueryString } from '../util/urlHelper';
import md5 from 'js-md5';
import { useHistory } from 'react-router-dom';
import { SuccessScreen } from '../common/SuccessScreen';

const PageContainer = styled.div`
  margin: 32px 16px;
  background: #F9F9F9;
  min-height: 100vh;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #112232;
  margin-bottom: 32px;
`;

const SectionContainer = styled.div`
  margin: 32px 0;
`;

const SectionHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #707070;
  margin-bottom: 4px;
  margin-top: 8px;
`;

const RadioLabel = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0 16px 0;
  height: 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1F2B3D;
  padding: 18px 20px;
`;

interface RadioItemProps {
  text: string;
  value: string,
  groupValue: string,
  setGroupValue: (newValue: string) => void
}

const RadioItem = ({text, value, groupValue, setGroupValue}: RadioItemProps) => {
  const checked = value === groupValue;
  return (
    <RadioLabel>
      <span>{text}</span>
      <Radio onChecked={() => setGroupValue(value)} size={20} checked={checked} />
    </RadioLabel>
  );
};

const TextInput = styled.input`
  height: 48px;
  width: 100%;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 14px 16px;
`;

const TextNotes = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #707070;
  margin-top: 8px;
  margin-button: 24px;
`;

export const ActivatePage = () => {
  const [storeId, setStoreId] = useState('');
  const [storeName, setStoreName] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [groupValue, setGroupValue] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const params = parseQueryString();
    const sid = params.get('storeId');
    if (sid) {
      setStoreId(sid);
    } else {
      setError('参数错误');
    }
    setStoreName(decodeURIComponent(params.get('storeName') || ''));
  }, []);

  const onSubmit = () => {
    setLoading(true);
    httpPostJson(
      `${CONFIG.API_URL}/store/${storeId}/activation`,
      {
        "store_id": storeId,
        "ecd_strategy": groupValue,
        "eleme_username": userName,
        "eleme_password": password ? md5(password) : ''// MD5 Encrypted
      },
      () => setSuccess(true),
      setError,
      () => setLoading(false)
    );
  };

  if (error) {
    return <ErrorScreen title="出错啦" msg={error}/>
  }

  if (loading) {
    return (
      <PageContainer>
        <Loading />
      </PageContainer>
    );
  }

  const goBack = () => {
    history.goBack();
  };

  if (success) {
    return (
      <PageContainer>
        <SuccessScreen title="激活成功" msg="您的发券宝已经激活成功。如有问题，请联系客服18918275310" />
        <SubmitButton onClick={goBack}>返回</SubmitButton>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Title>请提供相关信息，激活发券宝</Title>
      <SectionContainer>
        <SectionHeader>发券目的</SectionHeader>
        <RadioItem text="尽量多地获取新用户" value="MAX_ORDER_VOLUME" groupValue={groupValue} setGroupValue={setGroupValue}/>
        <RadioItem text="尽量多地增加利润" value="MAX_PROFIT" groupValue={groupValue} setGroupValue={setGroupValue}/>
      </SectionContainer>
      <SectionContainer>
        <SectionHeader>饿了么用户名</SectionHeader>
        <TextInput type="text" value={userName} onChange={e => setUserName(e.target.value)}/>
        <TextNotes>选填，请输入{storeName}用户名</TextNotes>
        <SectionHeader>饿了么密码</SectionHeader>
        <TextInput type="password" value={password} onChange={e => setPassword(e.target.value)}/>
        <TextNotes>选填，但填写后会帮助优化你的发券策略</TextNotes>
      </SectionContainer>
      <SubmitButton onClick={onSubmit} disabled={!groupValue || loading}>激活发券宝</SubmitButton>
    </PageContainer>
  );
};
