import React, { useContext } from 'react';
import styled from 'styled-components';
import { BarChart, Bar,  XAxis, Tooltip } from 'recharts';

import { Panel } from './Panel';
import { ReportPeriod, ReportStateContext } from './ReportState';
import { Loading } from '../common/Loading';
import { Toggle } from './Toggle';

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

const LegendsDiv = styled.div`
  display: grid;
  width: 180px;
  grid-template-columns: 55% 45%;
`;

const LegendDesc = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #707070;
  margin-left: 10px;
`;

const LegendLabel = styled.div`
  margin-left: 10px;
`;

const LegendColor = styled.div<{ color: string }>`
  background-color: ${props => props.color};
  width: 12px;
  height: 12px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 6px;
`;

const LegendValue = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #112232;
`;

const DateRangeDiv = styled.div`
  margin: 3px;
  > select {
    border: 1px solid #dbdbdb;
    padding: 5px 3px;
    border-radius: 6px;
    background-color: white;
  }
`;

const StyledChart = styled(BarChart)`
  text {
    font-size: 70%;
    font-weight: 600;
  }
`;

interface StorePerformance {
  fromEcd: number,
  other: number
}

export const ChartPanel = React.memo(() => {
  const {
    state: {
      loading,
      period,
      data
    },
    dispatch
  } = useContext(ReportStateContext);
  if (!data || loading) {
    return (
    <Panel>
      {loading && <Loading />}
    </Panel>);
  }
  const { daily_stats } = data;
  if (!daily_stats || !daily_stats.length) {
    return (
      <Panel></Panel>
    );
  }
  const { fromEcd, other } = daily_stats.reduce<StorePerformance>((total, daily) => {
    return {
      fromEcd: total.fromEcd + daily.store_performance.eaters_from_ecd,
      other: total.other + daily.store_performance.eaters_from_other_source
    }
  }, { fromEcd: 0, other: 0 });
  const chartData = daily_stats.map(daily => ({
    date: daily.date,
    fromEcd: daily.store_performance.eaters_from_ecd,
    other: daily.store_performance.eaters_from_other_source
  }));
  const barSize = period === ReportPeriod.THIS_WEEK ? 20 : 5;
  return (
  <Panel>
    <ChartHeader>
      <LegendsDiv>
        <LegendDesc>发券宝引流新客</LegendDesc>
        <LegendDesc>其他新客</LegendDesc>
        <LegendLabel>
          <LegendColor color="#FF5530"/>
          <LegendValue>{fromEcd}</LegendValue>
        </LegendLabel>
        <LegendLabel>
          <LegendColor color="#3C5266"/>
          <LegendValue>{other}</LegendValue>
        </LegendLabel>
      </LegendsDiv>
      <DateRangeDiv>
        <Toggle
          options={[
            {value: '1W', label: '本周'},
            {value: '1M', label: '本月'},
          ]}
          value={period}
          onToggle={value => dispatch({type: 'PERIOD_CHANGE', payload: value})}
        />
      </DateRangeDiv>
    </ChartHeader>
    <StyledChart
      width={320}
      height={180}
      data={chartData}
      margin={{
        top: 0,
        right: 10,
        left: 0,
        bottom: 5,
      }}
    >
      <XAxis
        dataKey="date"
        axisLine={false}
        tickLine={false}
        tickFormatter={(value, index) => value.substring(5)}
      />
      <Tooltip formatter={
        (value: number, name: string) => {
          switch(name) {
            case 'fromEcd': 
              return [value, '发券宝'];
            case 'other':
            default:
              return [value, '其 他'];
          }
        }
      }/>
      <Bar dataKey="fromEcd" stackId="a" fill="#FF5530" barSize={barSize} />
      <Bar dataKey="other" stackId="a" fill="#3C5266" barSize={barSize} />
    </StyledChart>
  </Panel>);
});
