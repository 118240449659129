import React from 'react';
import styled from 'styled-components';

const SubmitButtonWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 16px 32px 16px;
  background-color: #FFFFFF;
`;

const SubmitButtonInnerDiv = styled.div<{disabled?: boolean}>`
  text-align: center;
  padding: 12px;
  background: ${props => props.disabled ? '#A5A5A5' : '#FF5530'};
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
`;

interface SubmitButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

export const SubmitButton = ({onClick, disabled, children}: SubmitButtonProps) => {
  const handleClick = () => !disabled && onClick && onClick();
  return (
    <SubmitButtonWrapper>
      <SubmitButtonInnerDiv disabled={disabled} onClick={handleClick}>{children}</SubmitButtonInnerDiv>
    </SubmitButtonWrapper>
  );
};