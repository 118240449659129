export interface ErrorWithCode {
  error_code: string;
  msg: string;
}

const httpFetchJson = (
  url: string,
  method: 'GET' | 'POST',
  onSuccess: (result: any) => void,
  onFail: (errorMsg: string) => void,
  onFinish?: () => void, // will be invoked on both success & fail case
  data?: object,
) => {
  const opts = {
    method,
    body:JSON.stringify(data),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  fetch(url, opts)
    .then(res => {
      if(res.ok || res.status === 400) {
        return res.json();
      }
      else {
        throw res.statusText;
      }
    })
    .then(resJson => {
      onFinish && onFinish();
      if (resJson.error_code) {
        throw resJson.error_code;
      } else {
        onSuccess(resJson);
      }
    })
    .catch(err => {
      onFinish && onFinish();
      onFail(err.toString());
    });
};

export const httpPostJson = (
  url: string,
  data: object,
  onSuccess: (result: any) => void,
  onFail: (errorMsg: string) => void,
  onFinish?: () => void, // will be invoked on both success & fail case
) => {
    httpFetchJson(url, 'POST', onSuccess, onFail, onFinish, data)
};

export const httpGetJson = (
  url: string,
  onSuccess: (result: any) => void,
  onFail: (errorMsg: string) => void,
  onFinish?: () => void // will be invoked on both success & fail case
) => {
  httpFetchJson(url, 'GET', onSuccess, onFail, onFinish);
};
